export const reviewData = [
  {
    customerName:"Neha Parikh",
    ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/four-half-rating.png",
    recommendation:"Very Satisfied",
    customerReview:"Having a coach come to our society and train my child was an absolute game-changer! It saved a lot of time for me as i am busy with my work. The sessions felt super personalized, and my child could see real improvement in skills. Loved the convenience and the quality of coaching!",
    tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
    userType:"At Home User",
  },
  {
    customerName:"Pinang Parekh",
    ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewStar.png",
    recommendation:"Highly Recommended",
    customerReview:"It is super easy to book a game and just show up to play! No hassle, no long waits—just pure fun. The venues are great, and it’s perfect for when you just want to get a game in with friends!",
    tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
    userType:"Pay N Play User",
  },
  {
    customerName:"Yamini Singh",
    ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-home-page-2025/four-half-rating.png",
    recommendation:"Very Satisfied",
    customerReview:"Finding and joining sports events has never been this simple! The platform is smooth, the events are well-organized, and it’s a great way to meet other players and compete. The app is a must have for sports lovers and enthusiasts!",
    tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
    userType:"Events User",
  },
  {
    customerName:"Harsh Sheth",
    ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewStar.png",
    recommendation:"Highly Recommended",
    customerReview:"Finally found a place where I can get quality sports gear and accessories without second-guessing my choices! The products are great, and I love that I can find exactly what I need with expert recommendations. It’s super convenient to use!",
    tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
    userType:"KM Shop User",
  },
  {
    customerName:"Mohit Vasava",
    ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewStar.png",
    recommendation:"Highly Recommended",
    customerReview:"I used KheloMore platform to book event, & had a great experience! From easy event discovery to smooth booking & excellent customer support from Abhishek, everything was top-notch. Platform is user-friendly, well-organized, & makes sports event management effortless. A big shoutout to the KheloMore team for their great service! Keep up the great work! ",
    tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
    userType:"Events User",
  },
  // {
  //   customerName:"Jerry Jose",
  //   ratingImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewStar.png",
  //   customerReview:"Good experience with the khelomore. The best thing about this app is it provides the best discount in the market. Playo is little costly when comparing to khelomore. It is a money saver plus it is the best alternative for playo. Highly recommended",
  //   tagImageURL:"https://km-landing.s3.ap-south-1.amazonaws.com/Images/km-homePageNew/reviewTag.png",
  //   userType:"Pay N Play User",
  // },
];