import { makeStyles } from "@material-ui/core/styles";
import { recommendedRules } from "graphql";

const style = makeStyles((theme) => ({
  scrollContainer: {
    overflowY: "hidden",
    // whiteSpace: "nowrap",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-moz-scrollbars": {
      display: "none",
    },
  },
  homeBanner: {
    borderRadius: "16px",
    border: "1px solid rgba(238, 238, 238, 0.93)",
    background: "#FFF",
    width: "100%",
    padding: "12px",
    marginRight: "16px",
    marginTop: "16px",
    position: "relative",
  },
  cardName: {
    textAlign: "center",
    color: "#212121",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "110%",
    letterSpacing: "0.5px",
  },
  kmGallery: {
    color: "#212121",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "110%",
    letterSpacing: "0.1px",
    padding: "16px 0px",
  },
  cusName: {
    color: "#212121",
    fontSize: "17px",
    fontWeight: 700,
    lineHeight: "normal",
  },
  ratingImg: {
    width: "80px",
    margin: "8px 0px",
  },
  reviewText: {
    color: "#212121",
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "120%",
    letterSpacing: "0.15px",
    width: "420px",
  },
  mt30: {
    marginTop: "30px",
  },
  reviewTag: {
    width: "6px",
    height: "8px",
    marginRight: "6px",
  },
  userType: {
    color: "#ff4e00",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "10px",
    letterSpacing: "0.6px",
  },
  recommendation: {
    display:"inline-block",
    margin: "8px 0px",
    color: "#757575",
    fontWeight: "400",
    fontSize: "12px",
    marginLeft: "8px",
  },
  "@media (max-width: 550px)": {
    homeBannerCard: {
      maxWidth: "550px",
      "& a": {
        width: "100%",
      },
      "& img": {
        width: "100%",
      },
    },
    homeBanner: {
      marginTop: "0px",
      width: "550px",
    },
    kmGallery: {
      fontSize: "15px",
      fontWeight: "600",
      lineHeight: "110%",
      letterSpacing: "0.1px",
      padding: "0px 0px 16px 0px",
    },
    scrollContainer: {
      padding: "0px",
    },
    reviewText: {
      width: "320px",
    },
    trainingCard: {
      background: "#FFFFFF",
      margin: "16px",
      background: "#FDFDFD",
    },
    carouselBannerDiv: {
      padding: "8px 16px",
      maxWidth: "550px",
      minWidth: "328px",
      height: 150,
      "& img": {
        display: "block",
        width: "100%",
        height: "100%",
      },
    },
    ratingImg: {
      width: "80px",
      margin: "8px 0px",
    },
    reviewTag: {
      width: "6px",
      height: "8px",
      marginRight: "6px",
    },
  },
  carouselBannerDiv: {
    padding: "8px 16px",
    maxWidth: "550px",
    minWidth: "328px",
    height: 150,
    "& img": {
      display: "block",
      width: "100%",
      height: "100%",
    },
  },

  flex: {
    display: "flex",
  },
  spaceBw: {
    justifyContent: "space-between",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  alignCenter: {
    alignItems: "center",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
}));

export default style;
