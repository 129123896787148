
import React, {useState} from "react";
import { Helmet } from "react-helmet";

function SeoManager() {
  const state = {
    pageTitle: "Book Sports Venue, Coaches & Academies Online | KheloMore",
    socialTitle: "Book Sports Venue, Coaches & Academies Online | KheloMore",
    socialDescription: "Book sports venues, coaching, and events with KheloMore. Explore courts, turfs, and play zones near you. Enjoy easy booking, great offers, and expert coaching!",
    description: "Book sports venues, coaching, and events with KheloMore. Explore courts, turfs, and play zones near you. Enjoy easy booking, great offers, and expert coaching!",
    keyWords: "sports venues in India, sports coaches in India, sports academies in India, sports coaching in India, sports classes in India",
    h1: "Book Sports Venue, Coaches & Academies Online | KheloMore",
    h2: '',
    image: "https://s3.ap-south-1.amazonaws.com/khelomore-images/home/HomeOg.jpg",
    baseURL: "https://www.khelomore.com/"
  };

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href={window?.location?.href} />
      {/* <meta name="description" content={state.description} /> */}
      <meta name="keywords" content={state.keyWords} />
      <meta property="og:title" content={state.socialTitle} />
      <meta property="og:description" content={state.socialDescription} />
      <meta property="og:image" content={state.image} />
      <meta property="og:image:url" content={state.image} />
      <meta property="og:url" content={state.baseURL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@KheloMore" />
      <meta name="twitter:title" content="Largest sports ecosystem in India" />
      <meta name="twitter:description" content="Largest sports ecosystem in India. Book sports venues, academies and coaches." />
      <meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1848302627966390272/iVzRCqty.jpg" />
      <meta name="robots" content="index, follow" />

      {/* ----------------OGCard Section ------------------------- */}
      <meta property="og:type" content="website" />
      {/* <meta property="og:title" content="Khelomore" /> */}
      <meta property="og:site_name" content="Khelomore" />
      <meta property="og:locale" content="En" />
     
    </Helmet>
  );
}
export default SeoManager;